import { extendTheme } from '@chakra-ui/react'
import "@fontsource/fira-code";
import "@fontsource/source-sans-pro"
import "@fontsource/inter"
const theme = extendTheme({
    styles:{
      global:{
        'html, body':{
          background: 'black',
        }
      }
    },
    fonts:{
      heading: `'Inter'`,
      body: `'Inter'`,
      text: `'Inter'`,
      link: `'Inter'`
    },
    fontWeights: {
        normal: 100,
        medium: 200,
        bold: 300,
        super: 1000
      },
  })

export default theme