import create from "zustand";
import { getActiveAccount, disconnectWallet } from "./utils/wallet"

const initialState = {
    wall: null,
  }

export const useCurrentUserStore = create((set) => ({
    
    currentUser: null,
    wall: null,
    //...whatever you need
    fetchCurrentUser: async () => {
      const account = await getActiveAccount()
      set({wall: account})
      //console.log('useCurrentUser',account)
      //return (account)
      //setCurrentUser(account)
      // ...whatever
    },
    resetStore: async () => {
        const { wallet } = await disconnectWallet()
        console.log('resetStore', wallet)
        set({wall: null})} ,
  }));

