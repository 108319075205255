import { TezosToolkit } from "@taquito/taquito";
import { BeaconWallet } from "@taquito/beacon-wallet";

const contractAddress= "KT1Wn2kkKmdbyLWBiLXWCkE7fKj1LsLKar2A"

const preferredNetwork = "mainnet";
const options = {
  name: "NFT",
  iconUrl: "https://tezostaquito.io/img/favicon.png",
  preferredNetwork: preferredNetwork,
};
const rpcURL = "https://mainnet.smartpy.io";

const wallet = typeof window !== 'undefined' ? new BeaconWallet(options) : undefined

const getActiveAccount = async () => {
  return await wallet.client.getActiveAccount();
};

const connectWallet = async () => {
  let account = await wallet.client.getActiveAccount();

  if (!account) {
    await wallet.requestPermissions({
      network: { type: preferredNetwork },
    });
    account = await wallet.client.getActiveAccount();
  }
  return { success: true, wallet: account.address };
};

const disconnectWallet = async () => {
  await wallet.disconnect();
  return { success: true, wallet: null };
};

const checkIfWalletConnected = async (wallet) => {
  try {
    const activeAccount = await wallet.client.getActiveAccount();
    if (!activeAccount) {
      await wallet.client.requestPermissions({
        type: { network: preferredNetwork },
      });
    }
    return {
      success: true,
    };
  } catch (error) {
    return {
      success: false,
      error,
    };
  }
};

export const verify = async (name) => {
  // const wallet = new BeaconWallet(options);
  const response = await checkIfWalletConnected(wallet);

  if (response.success) {
    const tezos = new TezosToolkit(rpcURL);
    tezos.setWalletProvider(wallet);
    const contract = await tezos.wallet.at(contractAddress);
    const operation = await contract.methods.verify(name).send();
    console.log(operation)
    const result = await operation.confirmation();
    console.log(result);
  }
};

export const verifyBatch = async (names) => {
  // const wallet = new BeaconWallet(options);
  const response = await checkIfWalletConnected(wallet);
  if (response.success) {
    console.log(names)
    const tezos = new TezosToolkit(rpcURL)
    tezos.setWalletProvider(wallet);
    const contract = await tezos.wallet.at(contractAddress);
    
    let batch = tezos.wallet.batch()
    for (const address of names) {
      batch = batch.withContractCall(
        contract.methods.moderate(address,4,10))
    }
    
    console.log(batch)
    let op = await batch.send()
    console.log(op)
    await op.confirmation(1)
  }
};


export {
  connectWallet,
  disconnectWallet,
  getActiveAccount,
  checkIfWalletConnected,
};
