import * as React from "react"
import PropTypes from "prop-types"
//import { Link } from "gatsby"
import { Link as GatsbyLink } from 'gatsby'
import { Flex, Box, Container, Spacer, Link, Text, Button, Center } from '@chakra-ui/react'

import {
  connectWallet,
  disconnectWallet,
} from "../utils/wallet";

import { useEffect, useState } from "react";

import { useCurrentUserStore } from "../UseCurrentUser"
import { equalTo } from "firebase/database"


const Header = ({ siteTitle }) => {
  const { wall, fetchCurrentUser, resetStore } = useCurrentUserStore()

  const [wallet, setWallet] = useState()

  useEffect(() => {
    const func = async () => {
        fetchCurrentUser()
        if(wall){
          setWallet(wall.address)
        }
        
    };
    func()
  }, [])


  const handleConnectWallet = async () => {
    console.log('handleConnectWallet')
    const { wallet } = await connectWallet()
    fetchCurrentUser()
    setWallet(wallet)

  }
  const handleDisconnectWallet = async () => {
    console.log('handleDisconnectWallet')
    const { wallet } = await disconnectWallet()
    resetStore()
    setWallet(null)
    //setWallet(wallet)
  }
  
  return (

  <Box px={[2,8,8]} py={4} minWidth='100%' background='black'>
    <Flex minWidth='100%' color='white'>
      <Center>
        <Text fontWeight={900}>#respectroyalties</Text>
      </Center>
      <Spacer/>
      <Center>
        <Button display='none' right='auto' colorScheme='black'
                onClick={wallet? handleDisconnectWallet : handleConnectWallet}
              > 
                {" "}
                {wallet
                  ? wallet.slice(0, 4) +
                    "..." +
                    wallet.slice(wallet.length - 4, wallet.length)
                  : "Connect"}
        </Button>
      </Center>
    </Flex>
  
  </Box>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
