import React from "react"
import { graphql } from "gatsby"
import axios from 'axios'
import {Box, Button, Center, Divider, Flex, List, ListIcon, ListItem, Spacer, Text, UnorderedList, Wrap, WrapItem} from '@chakra-ui/react'
import { Icon, createIcon } from '@chakra-ui/react'
import { UpDownIcon } from "../icons/icons"
import { ArrowForwardIcon } from "@chakra-ui/icons"

function Feature(props) {
  const prop = props
  return (
    <Box color='white' background='black' height='100%'>
        <Text fontWeight={900} fontSize={[70,80,126]} lineHeight={['60px','70px','110px']} pb={12} ml={[0,0,-2]}>Respect Artist Royalties</Text>
        <Center>
      <Box border='1px' borderRadius='50px' width={['100%','100%','650px']}>
        <Center>
        <Text justify='center' px={7} fontWeight={400} fontSize={[20,25,40]}>Without artists, <Text as='span' fontWeight={900}>there is no art.</Text></Text>
        </Center>
      </Box>
      </Center>
      <Text fontWeight={500} fontSize={[20,20,30]} pt={10} pb={4}>We pledge to</Text>
      <List fontSize={[20,30,40]}>
        <ListItem><ListIcon as={ArrowForwardIcon}/>Never switch to 0% creator royalties</ListItem>
        <ListItem><ListIcon as={ArrowForwardIcon}/>Protect artists' earning rights</ListItem>
        <ListItem><ListIcon as={ArrowForwardIcon}/>Empower artists in web3</ListItem>
      </List>
      <Divider pt={12} />
      
    </Box>
  )
}
export default Feature